import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
  buildQuery,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import PdfGenerator from '../../components/PdfGenerator';
import { Checkbox } from '@paljs/ui';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const SpecGen = () => {
  const [customerOptions, setCustomerOptions] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [jobCardOptions, setJobCardOptions] = useState([]);
  const [pocOptions, setPocOptions] = useState([]);
  const [apiResponse, setApiResponse] = useState([]);
  const [packingSlips, setPackingSlips] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPages] = useState(0);
  const [labels, setLabels] = useState({ customer: '', sort: [], jobCard: [], poc: [], shadeString: '' });
  const [currSpecHeadData, setCurrSpecHeadData] = useState({
    customer: { name: '', id: '' },
    poc: { id: '' },
    sort: { sortName: '', id: '' },
  });
  const [tableData, setTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState('');
  const [totMeters, setTotMeters] = useState({ totalMeters: 0 });
  const [currShadeString, setCurrShadeString] = useState('');
  const [sgAll, setSgAll] = useState(false);
  const [dsAll, setDsAll] = useState(false);
  const filterColumns = [
    'id',
    'customer.name',
    'processOrder.id',
    'processOrder.shade',
    'customerSort.sortName',
    'totalMeters',
  ];
  const [currId, setCurId] = useState('');
  const [globalSGDate, setGlobalSGDate] = useState(dateFormat(new Date()));
  const [globalDispatchDate, setGlobalDispatchDate] = useState(dateFormat(new Date()));
  const optionsSetter = (response, customer = true, sort = true, jobCard = true, poc = true) => {
    const currJobCardOptions = [{ label: 'All', value: 'all' }];
    const currCustomerOptions = [];
    const currCustSortOptions = [];
    const currPocOptions = [];
    response.forEach((t) => {
      let temJbOpt = {
        label: `${t?.id}-${t?.attributes?.idLotNum}`,
        value: `${t?.id}`,
      };
      let temCustOpt = {
        label: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes?.name}`,
        value: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.id}`,
      };
      let temSortOpt = {
        label: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.sortName}`,
        value: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.id}`,
      };
      let temPocOptions = {
        label: `${t?.attributes?.processOrder?.data?.id}`,
        value: `${t?.attributes?.processOrder?.data?.id}`,
      };
      if (temJbOpt.label !== 'undefined') {
        currJobCardOptions.push(temJbOpt);
      }
      if (temSortOpt.label !== 'undefined') {
        currCustSortOptions.push(temSortOpt);
      }
      if (temCustOpt.label !== 'undefined') {
        currCustomerOptions.push(temCustOpt);
      }
      if (temPocOptions.label !== 'undefined') {
        currPocOptions.push(temPocOptions);
      }
    });
    if (jobCard) {
      setJobCardOptions(arrUnique(currJobCardOptions));
    }
    if (customer) {
      setCustomerOptions(arrUnique(currCustomerOptions));
    }
    if (poc) {
      setPocOptions(arrUnique(currPocOptions));
    }
    if (sort) {
      setSortOptions(arrUnique(currCustSortOptions));
    }
  };
  const getApi = () => {
    axios
      .get(
        `${baseUri()}packing-slips?sort[0]=jobCard.id:desc&filters[$or][0][dispatch][$eq]=false&filters[$or][1][dispatch][$null]=true&fields[0]=dispatch&populate[jobCard][fields][0]=idLotNum&populate[jobCard][populate][processOrder][fields][0]=id&populate[jobCard][populate][processOrder][fields][1]=shade&populate[jobCard][populate][processOrder][fields][2]=pocType&populate[jobCard][populate][processOrder][populate][customersSort][populate][user][fields][0]=name&populate[jobCard][populate][processOrder][populate][customersSort][fields][0]=sortName`,
      )
      .then((response) => {
        response = response.data.data;
        response = response.map((t) => t.attributes?.jobCard?.data);
        setApiResponse(response);
        optionsSetter(response);
        hideLoader();
      });
  };
  const getTableData = () => {
    axios
      .get(
        baseUri() +
          buildQuery(
            `spec-gens?sort[0]=id:desc&fileds[0]=id&populate[customer][fields][0]=name&populate[customerSort][fields][0]=sortName&populate[processOrder][fields][0]=id&populate[processOrder][fields][1]=shade&pagination[page]=${page}`,
            tableFilter,
            filterColumns,
          ),
      )
      .then((response) => {
        setTableData(response.data.data);
        setTotalPages(response.data.meta.pagination.pageCount);
      });
  };
  const arrUnique = (arr) => {
    const map = new Map();
    arr.forEach((itm) => {
      map.set(JSON.stringify(itm), itm);
    });
    return [...map.values()];
  };
  const customerFilterOptions = (labelValue = labels.customer.label) => {
    let filteredApiResponse = apiResponse.filter(
      (t) =>
        t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes?.name ===
        labelValue,
    );
    return filteredApiResponse;
  };
  const sortFilterOptions = (currLabel = labels.sort) => {
    let filteredApiResponse = apiResponse.filter(
      (t) =>
        t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.sortName === currLabel.label,
    );
    return filteredApiResponse;
  };
  const pocFilterOptions = (currLabel = labels.poc) => {
    let filteredApiResponse = apiResponse.filter((t: any) => {
      return +t?.attributes?.processOrder?.data?.id === +currLabel.label;
    });
    return filteredApiResponse;
  };
  const jobCardFilterChange = (label = labels) => {
    if (label.jobCard.length === 0) {
      optionsSetter(apiResponse, false);
    }
    let filteredApiResponse = [];
    let custMap = new Map();
    label.jobCard.forEach((currLabel) => {
      let currArr = apiResponse.filter((t: any) => {
        if (+t.id === +currLabel.value) {
          custMap.set(
            t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes?.name,
            {
              customer: {
                label:
                  t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes
                    ?.name,
                value: t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.id,
              },
              sort: {
                label: t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.sortName,
                value: t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.id,
              },
              poc: {
                label: t.attributes?.processOrder?.data?.id,
                value: t.attributes?.processOrder?.data?.id,
              },
              shadeString: `${t.attributes?.processOrder?.data?.attributes?.pocType}-${t.attributes?.processOrder?.data?.attributes?.shade}`,
            },
          );
        }
        return +t.id === +currLabel.value;
      });
      filteredApiResponse.push(currArr);
    });
    filteredApiResponse = filteredApiResponse.flat();
    optionsSetter(filteredApiResponse, false, true, false, true);
    const customersVal = [...custMap.values()];
    if (customersVal.length === 1 && customersVal[0].customer.label !== undefined) {
      setLabels({ ...label, ...customersVal[0] });
    } else {
      setLabels({
        ...label,
        customer: '',
        sort: '',
        poc: '',
        shadeString: '',
      });
    }
  };
  const getPackingSlips = async () => {
    showLoader();
    let jobCardIds = labels.jobCard.map((t) => +t.value);
    let response = await axios.get(`${baseUri()}packing-slips/frmJbCrd?jobCard=${jobCardIds}`);
    response = await response.data.data;
    setCurrSpecHeadData({
      customer: { name: labels.customer.label, id: labels.customer.value },
      poc: { id: labels.poc.label },
      sort: { sortName: labels.sort.label, id: labels.sort.value },
    });
    setPackingSlips(response);
    setCurId('');
    hideLoader();
  };
  const handlePackingSlipChange = (idx, key, value) => {
    let currPackingSlipArray = [...packingSlips];
    currPackingSlipArray[idx][key] = value;
    setPackingSlips(currPackingSlipArray);
  };
  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };
  const editSpec = async (id) => {
    showLoader();
    setPackingSlips([]);
    let response = await axios.get(`${baseUri()}spec-gens/${id}?populate=*`);
    response = await response.data.data;
    const newPackingSlipsArray = [];
    response.attributes.packing_slips.data.forEach((t) => {
      let temp = { id: t.id, ...t.attributes };
      newPackingSlipsArray.push(temp);
    });
    setPackingSlips(newPackingSlipsArray);
    setCurrSpecHeadData({
      customer: {
        name: response.attributes.customer?.data?.attributes?.name,
        id: response.attributes?.customer?.data?.id,
      },
      poc: {
        id: response.attributes.processOrder?.data?.id,
      },
      sort: {
        sortName: response.attributes?.customerSort?.data?.attributes?.sortName,
        id: response.attributes?.customerSort?.data?.id,
      },
    });
    setCurId(response.id);
    hideLoader();
  };
  const columns = [
    {
      name: 'Action',
      maxWidth: '14%',
      cell: (row) => (
        <div className="action-container">
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            id={'editData' + row.id}
            onClick={() => {
              editSpec(row.id);
            }}
          >
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
        </div>
      ),
    },
    {
      name: 'Id',
      selector: (row) => row?.id || 'undefined',
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Customer',
      selector: (row) => row.attributes?.customer?.data?.attributes?.name || 'undefined',
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'POC',
      selector: (row) => row.attributes?.processOrder?.data?.id || 'undefined',
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Sort Name',
      selector: (row) => row.attributes?.customerSort?.data?.attributes?.sortName || 'undefined',
      sortable: true,
      maxWidth: '30%',
    },
    {
      name: 'Color',
      selector: (row) => row.attributes?.processOrder?.data?.attributes?.shade || '-',
      sortable: true,
      maxWidth: '30%',
    },
    {
      name: 'Total Meters',
      selector: (row) => (+row.attributes?.totalMeters).toFixed(2) || 'undefined',
      sortable: true,
      maxWidth: '10%',
    },
  ];
  useEffect(() => {
    showLoader();
    getApi();
  }, []);
  useEffect(() => {
    getTableData();
  }, [page]);
  useEffect(() => {
    let currTot = { totalMeters: 0 };
    packingSlips.forEach((t) => {
      if (t.sg) {
        if (currTot[t.category]) {
          currTot[t.category] += +t.baleTotal;
        } else {
          currTot[t.category] = 0;
          currTot[t.category] += +t.baleTotal;
        }
        currTot.totalMeters += +t.baleTotal;
      }
    });
    setTotMeters(currTot);
  }, [packingSlips]);
  let totString = '';
  for (let i in totMeters) {
    if (i === 'totalMeters') {
      totString += `Total:${+totMeters[i].toFixed(2)}, `;
    } else {
      totString += `${i}:${+totMeters[i].toFixed(2)}, `;
    }
  }
  return (
    <>
      <SEO title="Spec Gen / Dispatch" />
      <Col breakPoint={{ xs: 12, md: 12 }}>
        <Card>
          <header> Spec Gen / Dispatch</header>
          <CardBody className="cardbody">
            <Row>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>Customer</label>
                <SelectField
                  fullWidth
                  options={customerOptions}
                  id="customer"
                  name="customer"
                  placeholder="Select Customer"
                  value={labels.customer}
                  onChange={(e) => {
                    setLabels({
                      ...labels,
                      poc: '',
                      sort: '',
                      customer: {
                        label: e.label,
                        value: e.value,
                      },
                      jobCard: [],
                      shadeString: '',
                    });
                    optionsSetter(customerFilterOptions(e.label), false);
                  }}
                  className="pull-down multi-select"
                />
              </Col>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>Customer Sort</label>
                <SelectField
                  fullWidth
                  options={sortOptions}
                  id="customerSort"
                  name="customerSort"
                  placeholder="Select Customer Sort"
                  value={labels.sort}
                  onChange={(e) => {
                    const currFilteredSortOptions = sortFilterOptions(e);
                    setLabels({
                      ...labels,
                      sort: e,
                      customer: {
                        label:
                          currFilteredSortOptions[0].attributes?.processOrder?.data?.attributes?.customersSort?.data
                            ?.attributes?.user?.data?.attributes?.name,
                        value:
                          currFilteredSortOptions[0].attributes?.processOrder?.data?.attributes?.customersSort?.data
                            ?.attributes?.user?.data?.id,
                      },
                      poc: {
                        label: currFilteredSortOptions[0].attributes?.processOrder?.data?.id,
                        value: currFilteredSortOptions[0].attributes?.processOrder?.data?.id,
                      },
                      shadeString: `${currFilteredSortOptions[0].attributes?.processOrder?.data?.attributes?.pocType}-${currFilteredSortOptions[0].attributes?.processOrder?.data?.attributes?.shade}`,
                    });
                    optionsSetter(currFilteredSortOptions, false, false);
                  }}
                  className="pull-down multi-select"
                />
              </Col>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>Job Card / Lot Number</label>
                <SelectField
                  fullWidth
                  options={jobCardOptions}
                  id="customerSort"
                  name="customerSort"
                  placeholder="Select Job Card - Lot Number"
                  value={labels.jobCard}
                  onChange={(e) => {
                    if (e.length > 0 && e[e.length - 1].value === 'all') {
                      const currJobCardOptions = jobCardOptions.slice(1, jobCardOptions.length);
                      setLabels({ ...labels, jobCard: currJobCardOptions });
                      jobCardFilterChange({ ...labels, jobCard: currJobCardOptions });
                    } else {
                      setLabels({ ...labels, jobCard: e });
                      jobCardFilterChange({ ...labels, jobCard: e });
                    }
                  }}
                  isMulti
                  multiple
                  className="pull-down multi-select"
                />
              </Col>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>POC</label>
                <SelectField
                  fullWidth
                  options={pocOptions}
                  onChange={(e) => {
                    const currFilteredPOCOptions = pocFilterOptions(e);
                    setLabels({
                      ...labels,
                      poc: e,
                      jobCard: [],
                      sort: '',
                      customer: {
                        label:
                          currFilteredPOCOptions[0].attributes?.processOrder?.data?.attributes?.customersSort?.data
                            ?.attributes?.user?.data?.attributes?.name,
                        value:
                          currFilteredPOCOptions[0].attributes?.processOrder?.data?.attributes?.customersSort?.data
                            ?.attributes?.user?.data?.id,
                      },
                      shadeString: `${currFilteredPOCOptions[0].attributes?.processOrder?.data?.attributes?.pocType}-${currFilteredPOCOptions[0].attributes?.processOrder?.data?.attributes?.shade}`,
                    });
                    optionsSetter(currFilteredPOCOptions, false, true, true, false);
                  }}
                  id="customerSort"
                  name="customerSort"
                  placeholder="Select POC"
                  value={labels.poc}
                  className="pull-down multi-select"
                />
              </Col>
              <Col
                breakPoint={{ xs: 12, md: 4 }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {labels.shadeString}
                </span>
              </Col>
              <Col
                breakPoint={{ xs: 12, md: 4 }}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Button
                  status="Success"
                  type="button"
                  className="btnrad"
                  shape=""
                  disabled={
                    labels.jobCard.length === 0 ||
                    labels.customer.length === 0 ||
                    labels.poc.length === 0 ||
                    labels.sort.length === 0
                  }
                  halfWidth
                  onClick={() => {
                    getPackingSlips();
                  }}
                >
                  Get Packing Slips
                </Button>
                &nbsp;&nbsp;
                <Button
                  type="button"
                  className="btnrad"
                  shape=""
                  halfWidth
                  onClick={() => {
                    setLabels({
                      customer: '',
                      jobCard: [],
                      poc: '',
                      sort: '',
                    });
                    setTimeout(() => {
                      optionsSetter(apiResponse);
                    }, 500);
                  }}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {packingSlips.length > 0 && (
          <Card>
            <header
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>
                Customer: {currSpecHeadData.customer.name} ({packingSlips.length} records)
              </span>
              <span>Sort: {currSpecHeadData.sort.sortName}</span>
              <span>POC: {currSpecHeadData.poc.id}</span>
              <span>{totString}</span>
              <div>
                <Button
                  onClick={async () => {
                    let currPackingSlips = packingSlips;
                    await currPackingSlips.forEach(async (t: any) => {
                      showLoader();
                      let res = await axios.put(`${baseUri()}packing-slips/${t.id}`, {
                        data: {
                          sg: t.sg ? t.sg : false,
                          sgDate: t.sgDate,
                          dispatch: t.dispatch ? t.dispatch : false,
                          dispatchDate: t.dispatchDate,
                          invoiceNumber: t.invoiceNumber,
                        },
                      });
                      res = res.data.data;
                      if (res) {
                        hideLoader();
                      }
                    });
                    if (totMeters.totalMeters <= 0) return;
                    currPackingSlips = currPackingSlips.filter((t) => t.sg);
                    if (currId) {
                      await axios.put(`${baseUri()}spec-gens/${currId}`, {
                        data: {
                          customer: currSpecHeadData.customer?.id || '',
                          customerSort: currSpecHeadData.sort?.id,
                          processOrder: currSpecHeadData.poc?.id,
                          packing_slips: currPackingSlips.map((t) => t.id),
                          totalMeters: totMeters.totalMeters,
                        },
                      });
                    } else {
                      await axios.post(`${baseUri()}spec-gens`, {
                        data: {
                          customer: currSpecHeadData.customer?.id || '',
                          customerSort: currSpecHeadData.sort?.id,
                          processOrder: currSpecHeadData.poc?.id,
                          packing_slips: currPackingSlips.map((t) => t.id),
                          totalMeters: totMeters.totalMeters,
                        },
                      });
                    }
                    setPackingSlips([]);
                    getTableData();
                  }}
                  status="Success"
                >
                  Save
                </Button>
                &nbsp;&nbsp;
                <Button
                  onClick={() => {
                    setPackingSlips([]);
                    setCurId('');
                  }}
                  status="Basic"
                >
                  Reset
                </Button>
              </div>
            </header>
            <CardBody>
              <Col>
                <Row
                  middle="xs"
                  start="xs"
                  style={{
                    gap: '5px',
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    display: 'flex',
                    flexWrap: 'nowrap',
                    overflow: 'auto',
                  }}
                >
                  <span
                    style={{
                      width: '10%',
                      overflow: 'hidden',
                    }}
                  >
                    PKD Date
                  </span>
                  <span
                    style={{
                      width: '1.5%',
                      overflow: 'hidden',
                    }}
                  >
                    SG
                  </span>
                  <span
                    style={{
                      width: '10%',
                      overflow: 'hidden',
                    }}
                  >
                    SG Date
                  </span>
                  <span
                    style={{
                      width: '1.5%',
                    }}
                  >
                    Dis
                  </span>
                  <span
                    style={{
                      width: '10%',
                      overflow: 'hidden',
                    }}
                  >
                    Dispatch Date
                  </span>
                  <span
                    style={{
                      width: '10%',
                      overflow: 'hidden',
                    }}
                  >
                    Invoice
                  </span>
                  <span
                    style={{
                      width: '8.5%',
                      overflow: 'hidden',
                    }}
                  >
                    Bale No.
                  </span>
                  <span
                    style={{
                      width: '8.5%',
                      overflow: 'hidden',
                    }}
                  >
                    Tot Mts
                  </span>
                  <span
                    style={{
                      width: '8.5%',
                      overflow: 'hidden',
                    }}
                  >
                    Nt.Wt
                  </span>
                  <span
                    style={{
                      width: '8.5%',
                      overflow: 'hidden',
                    }}
                  >
                    Gr.Wt
                  </span>
                  <span
                    style={{
                      width: '8.5%',
                      overflow: 'hidden',
                    }}
                  >
                    Category
                  </span>
                  <span
                    style={{
                      width: '8.5%',
                      overflow: 'hidden',
                    }}
                  >
                    Pieces Pkd
                  </span>
                </Row>
                <Row
                  middle="xs"
                  start="xs"
                  style={{
                    gap: '5px',
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    display: 'flex',
                    flexWrap: 'nowrap',
                    overflow: 'auto',
                  }}
                >
                  <span
                    style={{
                      width: '10%',
                      overflow: 'hidden',
                    }}
                  ></span>

                  <Checkbox
                    style={{
                      width: '1.5%',
                    }}
                    checked={sgAll}
                    onChange={(e) => {
                      let currPackingSlipArray = [...packingSlips];
                      currPackingSlipArray = currPackingSlipArray.map((t: any) => {
                        return { ...t, sg: e, sgDate: dateFormat(globalSGDate) };
                      });
                      setPackingSlips(currPackingSlipArray);
                      setSgAll(e);
                    }}
                  />
                  <Input
                    style={{
                      width: '10%',
                      overflow: 'hidden',
                    }}
                  >
                    <input
                      type="date"
                      value={globalSGDate}
                      onChange={(e) => setGlobalSGDate(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Input>
                  <Checkbox
                    style={{
                      width: '1.5%',
                    }}
                    checked={dsAll}
                    onChange={(e) => {
                      let currPackingSlipArray: any = [...packingSlips];
                      currPackingSlipArray = currPackingSlipArray.map((t: any) => {
                        return { ...t, dispatch: e, dispatchDate: dateFormat(globalDispatchDate) };
                      });
                      setPackingSlips(currPackingSlipArray);
                      setDsAll(e);
                    }}
                  />
                  <Input
                    style={{
                      width: '10%',
                      overflow: 'hidden',
                    }}
                  >
                    <input
                      type="date"
                      value={globalDispatchDate}
                      onChange={(e) => setGlobalDispatchDate(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Input>
                  <span
                    style={{
                      width: '10%',
                      overflow: 'hidden',
                    }}
                  ></span>
                  <span
                    style={{
                      width: '8.5%',
                      overflow: 'hidden',
                    }}
                  ></span>
                  <span
                    style={{
                      width: '8.5%',
                      overflow: 'hidden',
                    }}
                  ></span>
                  <span
                    style={{
                      width: '8.5%',
                      overflow: 'hidden',
                    }}
                  ></span>
                  <span
                    style={{
                      width: '8.5%',
                      overflow: 'hidden',
                    }}
                  ></span>
                  <span
                    style={{
                      width: '8.5%',
                      overflow: 'hidden',
                    }}
                  ></span>
                  <span
                    style={{
                      width: '8.5%',
                      overflow: 'hidden',
                    }}
                  ></span>
                </Row>
                {packingSlips.map((t, idx) => (
                  <Row
                    key={idx}
                    middle="xs"
                    start="xs"
                    style={{
                      gap: '5px',
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      display: 'flex',
                      flexWrap: 'nowrap',
                      overflow: 'auto',
                    }}
                  >
                    <Input
                      style={{
                        width: '10%',
                        overflow: 'hidden',
                      }}
                    >
                      <input type="date" readOnly style={{ width: '100%' }} value={dateFormat(t.date)} />
                    </Input>
                    <Checkbox
                      checked={t.sg}
                      style={{
                        width: '1.5%',
                      }}
                      onChange={(e) => {
                        handlePackingSlipChange(idx, 'sg', e);
                        handlePackingSlipChange(idx, 'sgDate', dateFormat(globalSGDate));
                      }}
                    />
                    <Input
                      style={{
                        width: '10%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        type="date"
                        style={{ width: '100%' }}
                        value={t.sgDate ? dateFormat(t.sgDate) : t.sgDate}
                        onChange={(e) => {
                          handlePackingSlipChange(idx, 'sgDate', e.target.value);
                        }}
                      />
                    </Input>
                    <Checkbox
                      checked={t.dispatch}
                      style={{
                        width: '1.5%',
                      }}
                      onChange={(e) => {
                        if (!packingSlips[idx].sg) return;
                        handlePackingSlipChange(idx, 'dispatch', e);
                        handlePackingSlipChange(idx, 'dispatchDate', dateFormat(globalDispatchDate));
                      }}
                    />
                    <Input
                      style={{
                        width: '10%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        type="date"
                        style={{ width: '100%' }}
                        value={t.dispatchDate ? dateFormat(t.dispatchDate) : t.dispatchDate}
                        onChange={(e) => {
                          handlePackingSlipChange(idx, 'dispatchDate', e.target.value);
                        }}
                      />
                    </Input>
                    <Input
                      style={{
                        width: '10%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        type="text"
                        style={{
                          width: '100%',
                        }}
                        onChange={(e) => {
                          handlePackingSlipChange(idx, 'invoiceNumber', e.target.value);
                        }}
                        maxLength={20}
                        value={t.invoiceNumber}
                      />
                    </Input>
                    <Input
                      style={{
                        width: '8.5%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        readOnly
                        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        value={t.baleNum}
                      ></input>
                    </Input>
                    <Input
                      style={{
                        width: '8.5%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        readOnly
                        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        value={t.baleTotal}
                      ></input>
                    </Input>
                    <Input
                      style={{
                        width: '8.5%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        readOnly
                        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        value={t.ntWeight}
                      ></input>
                    </Input>
                    <Input
                      style={{
                        width: '8.5%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        readOnly
                        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        value={t.grWeight}
                      ></input>
                    </Input>
                    <Input
                      style={{
                        width: '8.5%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        readOnly
                        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        value={t.category}
                      ></input>
                    </Input>
                    <Input
                      style={{
                        width: '8.5%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        readOnly
                        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        value={t.piecesPacked}
                      ></input>
                    </Input>
                  </Row>
                ))}
              </Col>
            </CardBody>
          </Card>
        )}
      </Col>
      <Col breakPoint={{ xs: 12, md: 12 }}>
        <Card>
          <header id="list-data">Spec Gen/Dispatch</header>
          <CardBody>
            <Input fullWidth size="Small">
              <input
                type="text"
                onChange={(e) => setTableFilter(e.target.value)}
                placeholder="Filter"
                name="tableFilter"
                value={tableFilter}
              />
            </Input>
            <Button
              onClick={() => {
                if (page == 1) {
                  getTableData();
                } else {
                  setPage(1);
                }
              }}
            >
              Filter
            </Button>
            <DataTable
              columns={columns}
              data={tableData}
              fixedHeader
              allowOverflow={false}
              overflowY={false}
              overflowX={false}
              wrap={false}
              highlightOnHover={true}
            />
            <ReactPaginate
              className="pagination"
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePagination}
              pageRangeDisplayed={5}
              pageCount={totalPage}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SpecGen;
